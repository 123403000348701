import React from 'react'
import { graphql } from 'gatsby';

import Home from '../templates/home';

const HomePage = ({ data }) => {
  return <Home data={data} />
}

export default HomePage;

export const pageQuery = graphql`
  query {
    page: wordpressPage(wordpress_id: { eq: 7 }) {
      acf {
        bannerDescription: banner_description
        bannerCallToActions: banner_call_to_actions {
          title
          location
        }
        bannerImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }

        introTitle: intro_title
        introDescription: intro_description
        introImage: intro_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        points {
          title
          description
          icon {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 64) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`