import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Libraries
import _ from 'lodash'

// CSS
import './Testimonials.scss'

// Icon
import quote from 'img/icon/quote.svg'

const Testimonials = () => {
  const {
    allWordpressWpTestimonials: { edges: items },
  } = useStaticQuery(graphql`
    {
      allWordpressWpTestimonials(sort: { fields: date, order: ASC }, limit: 3) {
        edges {
          node {
            id: wordpress_id
            title
            acf {
              description
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="testimonials">
      <div className="row">
        {_.map(
          items,
          ({
            node: {
              id,
              title,
              acf: { image, description },
            },
          }) => (
            <div className="col-lg-4 mt-5 mt-lg-0" key={id}>
              <div className="testimonial position-relative">
                <BackgroundImage
                  className="testimonial-image"
                  fluid={image.localFile.childImageSharp.fluid}
                />
                <div className="testimonial-image-shadow" />
                <div className="px-5 py-5 position-relative">
                  <div className="testimonial-icon position-absolute color-background-main">
                    <img src={quote} alt="" />
                  </div>
                  <div
                    className="testimonial-content"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <div className="testimonial-title mt-3 text-uppercase font-size-s font-weight-l font-family-secondary color-text-secondary text-center">
                    {title}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default Testimonials
