import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

// Libraries
import _ from 'lodash'

// Components
import { BannerDefault, ContentDefault, ButtonDefault } from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Points from 'components/Points'
import RequestOffer from 'components/RequestOffer'
import Services from 'components/Services'
import Testimonials from 'components/Testimonials'

// Styles
import 'styles/templates/HomeTemplate.scss'

// Icon
import rightThinChevron from 'img/icon/right-thin-chevron.svg'

const VideoBanner = styled.div`
    height: 80vh;
    min-height: 900px;
    max-height: 1200px;
    position: relative;
   

    & h1 {
      font-size: 40px;;
      font-weight: 300;
      line-height: 36px;
      color: #fff;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    }

`

const BannerShadow = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    background: black;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.5)));
    background: linear-gradient(0deg , rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.5) 100%);
`

const VideoContainer = styled.div`
    height: 80vh;
    width: 100vh;
    position: absolute;
    left: 0;
    overflow: hidden;
    right: 0;
    min-width: 100%;
    min-height: 100%;
`

const LoopVideo = styled.video`
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        bannerDescription: banner_description
        bannerCallToActions: banner_call_to_actions {
          title
          location
        }
        bannerImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }

        introTitle: intro_title
        introDescription: intro_description
        introImage: intro_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const HomeTemplate = ({
  data: {
    page: {
      path,
      acf: {
        bannerImage,
        bannerDescription,
        bannerCallToActions,
        introTitle,
        introDescription,
        introImage,
      },
      yoastMeta,
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} image={bannerImage.localFile.childImageSharp.fixed.src} article />
      <div className="home-template">
        <VideoBanner className="d-none d-lg-block">
          <BannerShadow />
          <VideoContainer>
            <LoopVideo autoPlay muted loop>
              <source src="https://player.vimeo.com/external/538654399.hd.mp4?s=2e8ce99425c9c834738291be18dd98c644c7ad41&amp;profile_id=175" type="video/mp4" />
            </LoopVideo>
          </VideoContainer>
          <div className="container h-100">
            <div className="banner-content row h-100 align-items-center">
              <div className="col-lg-7 d-lg-flex flex-wrap align-items-center pt-lg-0 pt-5">
                <h1 className="pt-5 pt-lg-0 text-center text-lg-left mb-4 pb-3">
                  <div dangerouslySetInnerHTML={{ __html: bannerDescription }} />
                </h1>
                  <div className="d-flex flex-wrap">
                    <div className="w-100 text-center text-lg-left">
                      <ButtonDefault to="/diensten/de-rijdende-kerstmarkt/">
                        DE RIJDENDE KERSTMARKT
                      </ButtonDefault>
                    </div>
                    <div className="w-100 text-center text-lg-left">
                      <ButtonDefault to="/diensten/de-pop-up-kerstmarkt/">
                        DE POP-UP KERSTMARKT
                      </ButtonDefault>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </VideoBanner>

        <BannerDefault className="d-lg-none">
          <BackgroundImage
            className="banner-background-image"
            fluid={bannerImage.localFile.childImageSharp.fluid}
          />
          <div className="banner-content row">
            <div className="col-lg-7 d-lg-flex justify-content-start align-items-end pt-lg-0 pt-5">
              <h1 className="pt-5 pt-lg-0 text-center text-lg-left">
                <div dangerouslySetInnerHTML={{ __html: bannerDescription }} />
              </h1>
            </div>
            <div className="col-lg-5 pt-4">
              {_.map(bannerCallToActions, ({ title, location }, index) => (
                <Link
                  key={index}
                  className="banner-action mt-lg-5 mt-4"
                  to={location}
                >
                  <h2>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  </h2>
                  <div className="mt-2 text-right">
                    <div className="banner-action-go">
                      <img src={rightThinChevron} alt="Gaan" />
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </BannerDefault>

          
        <div className="home-template-intro">
          <div className="container pb-5">
            <div className="row">
              <div className="col-lg-7 py-5 pr-lg-5">
                <h3 className="mt-5">
                  <div dangerouslySetInnerHTML={{ __html: introTitle }} />
                </h3>
                <ContentDefault className="mt-5" html={introDescription} />
              </div>
              <div className="col-lg-5 position-relative d-none d-lg-flex justify-content-end">
                <div className="home-template-intro-block" />
                <BackgroundImage
                  // style={{ backgroundSize: 'contain', backgroundPosition: 'center bottom' }}
                  className="home-template-intro-image"
                  fluid={introImage.localFile.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>

          <div className="home-template-intro-points mt-lg-5 pt-lg-5">
            <div className="container pb-5">
              <Points />
            </div>
          </div>
        </div>

        <RequestOffer />

        <Services />

        <div className="container py-5 my-5">
          <Testimonials />
          <div className="mt-5 text-center">
            <Link
              className="home-template-testimonials-link px-3 font-size-sm font-weight-l text-uppercase"
              to="/klantcases/"
            >
              Lees alle klantcases 
              {' '}
              <img src={rightThinChevron} alt="" />
            </Link>
          </div>
        </div>

        <div>
          <RequestOffer isRed />
        </div>
      </div>
    </Layout>
  )
}

export default HomeTemplate
